
import { defineComponent, ref } from 'vue';
import { ArrivalConfirmation } from '@/models/Enums';

export default defineComponent({
    name: 'OrderArrivalConfirmationActions',
    props: {
        id: {
            type: String,
            required: false
        },
        align: {
            type: String,
            default: 'column'
        }
    },
    emits: ['save'],
    setup(props, { emit }) {
        function save(value: ArrivalConfirmation) {
            if (props.id) {
                emit('save', { value: value, id: props.id });
            } else {
                emit('save', value);
            }
        }

        function saveNotArrived() {
            save(ArrivalConfirmation.NotArrived);
        }

        function saveArrivedPartially() {
            save(ArrivalConfirmation.ArrivedPartially);
        }

        function saveArrived() {
            save(ArrivalConfirmation.Arrived);
        }

        return {
            saveNotArrived,
            saveArrivedPartially,
            saveArrived
        };
    }
});
