
  import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { SendContractFormRequest } from '@/models/Interfaces';
  import { Customer } from '@/models/Order';
  import { defineComponent, ref, onMounted  } from 'vue';
  
  export default defineComponent({
    name: "ContractFormModal",
    components: {
      CheckBoxInput
    },
    emits: ['updateVisibility', 'confirmed'],
    props: {
        leadCustomer: Customer
    },
    setup(props, { emit }) {
        const contractForm = ref<SendContractFormRequest>({
            orderId: '',
            customerEmail: '',
            customerMobile: '',
            hasBankAccountDetails: false,
            hasChangesAndCancellationsPolicy: false,
            hasPaymentConditions: false
        });

        onMounted(() => {
            if(props.leadCustomer){
                contractForm.value.customerEmail = props.leadCustomer.email || '';
                contractForm.value.customerMobile = props.leadCustomer.mobile || '';
            }
        });
  
        function close() {
            emit('updateVisibility', false);
            contractForm.value = {
                orderId: '',
                customerEmail: '',
                customerMobile: '',
                hasBankAccountDetails: false,
                hasChangesAndCancellationsPolicy: false,
                hasPaymentConditions: false
            };
        }
    
        function confirm() {
            emit('confirmed', {...contractForm.value});    
            close();
        }
    
        const canConfirm = () => contractForm.value.customerEmail && contractForm.value.customerMobile;
    
        function confirmButtonClass() {
            return canConfirm() ? 'save-button-wrapper' : 'grey-button-wrapper';
        }
    
        return {
            close,
            confirm,
            canConfirm,
            confirmButtonClass,
            contractForm
        };
    }
  });
  