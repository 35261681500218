
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        fromId: {
            type: String,
            required: true
        },
        calculated: {
            type: Boolean,
            required: true
        },
        submitText: {
            type: String
        }
    },
    async setup(props, { emit }) {
        function backEmit() {
            emit('backEmit');
        }
        return { backEmit };
    }
});
