
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        submitText: {
            type: String,
            required: false
        },
        backText: {
            type: String,
            required: false
        },
        title: {
            type: String
        },
        formId: {
            type: String,
            required: true
        },
        formDisabled: {
            type: Boolean,
            default: false
        },
        showSubmit: {
            type: Boolean,
            default: true
        },
        boxShadow: {
            type: Boolean,
            default: true
        }
    },
    async setup(props, { emit }) {
        function back() {
            emit('back');
        }
        return { back };
    }
});
